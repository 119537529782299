@import "../../../scss/_vars.scss";

.page-section {
	background-color: $bg-dark;
	border-top: 4px solid $bg-gray;
	border-bottom: 4px solid $bg-gray;
	padding: 3em 0;
	border-radius: 3em;
	z-index: 11;

	> *:not(:first-child){
		margin-top: 4em;
	}

	&.space {
		z-index: 10;
		border-radius: 0;
		border: none;
		padding: 6em 0;
		margin-top: -3em;
		margin-bottom: -3em;
		background: url("/assets/space-background.png") fixed ;
	}
}