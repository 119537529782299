@import "../../scss/_vars.scss";

.footer{
	display: flex;
		flex-direction: column;
		align-items: center;

	.top { 
		color: $font-color-light;
		a {
			color: $font-color-light
		}
	}

	.fade1 { 
		color: $bg-gray-fade2;
		a {
			color: $bg-gray-fade2
		}
	}

	.fade2 { 
		color: $bg-gray-fade1;
		a {
			color: $bg-gray-fade1
		}
	}

	.bottom { 
		color: $bg-gray;
		a {
			color: $bg-gray
		}
	}
}