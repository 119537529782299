@import "../../scss/_vars.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap";

.about-research.about-research.about-research {
	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		color: $font-color-dark
	}

	.content {
		display: flex;
		flex-direction: column;
	}

	.card-container {
		margin-top: 3em;

		width: 80vw;
	}

	@include media-breakpoint-up(lg) {
		.card-container {
			width: 65vw;
		}
	}


	.research-card {
		padding: 1em;
		border-radius: 1em;
		min-height: 15em;
		font-size: 1em;
		font-weight: bold;

		transition: all 0.2s ease-in-out;

		&:hover {
			transform: scale(110%);
		}

		>* {
			color: $font-color-dark;
		}

		>*:first-child {
			font-size: 1.2em;
		}

		&.one {
			background-color: #ffffff;
		}

		&.two {
			background-color: #eaf0df;
		}

		&.three {
			background-color: #dce6c7;
		}
		
	}
}