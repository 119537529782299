@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./_vars.scss";

@font-face {
    font-family: "MontserratLight";
    src: url(/assets/fonts/Montserrat-Light.ttf) format("truetype");
}

* { 
	font-family: "MontserratLight", sans-serif;
	color: $font-color-light;
}


body {
    overscroll-behavior: none;
}

.App {
	display: flex;
	flex-direction: column;
}
