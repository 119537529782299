@import "../../../scss/vars";

.timeline {
	display: grid;
	grid-auto-flow: column;

	> div:nth-child(3n+1) {
		transform: translateY(-0.2em);
		grid-column: 1;
		align-self: center;
		justify-self: right;
	}
	> div:nth-child(3n+2){
		justify-self: center;
		align-self: stretch;
		grid-column: 2;
		padding: 0 1em;
		position: relative;

		> div.bar {
			background-color: white;
			width: 3px;
			height: 100%;
		}

		> div.dot {
			height: 1em;
			width: 1em;
			justify-self: center;
			align-self: center;
			border-radius: 1em;
			background-color: white;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	> div:nth-child(3n){
		grid-column: 3;
		align-self: center;
		justify-self: left;
	}

	.time-period {
		margin-top: 0.2em;
		margin-bottom: 1em;
		display: flex;
		flex-direction: column;

		> *:last-child {
			color: $font-color-faded;
		}
	}
}