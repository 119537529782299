@import "../../scss/_vars.scss";

.splash-page {
	height: 100vh;

	display: flex;

	.btn {
		transition: all 0.3s ease;

		&:hover {
			transform: scale(1.1);
		}
	}

	.paralax {
		position: fixed;
	
		.paralax-content {
			position: absolute;
			top: 0;
			left: 0;
	
			> * {
				position: absolute;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100vh;
	
			}
		}
	
		.background {
			background-color: $bg-dark;
		}
	
		.content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: $font-color-light;
			text-align: center;

			.subtitle {
				margin: 0 2em;
			}

			h1, .btn {
				text-transform: lowercase;
			}

			.btn-row {
				display: flex;
				flex-direction: row;
				> *:not(:first-child) {
					margin-left: 1em;
				}
			}

			hr {
				width: 15em;
			}
		}
	}
}
