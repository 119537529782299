@import "../../scss/_vars.scss";

.about-teaching {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 2em;
	a {
		color: $font-color-faded
	}
	.content {
		display: flex;
		flex-direction: column;
		width: 65vw;
	}
	.head { 
		color: $font-color-light
	}
	.text { 
		color: $font-color-faded;
		margin-left: 3em
	
	}
}

