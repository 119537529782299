@import "../../scss/vars";

.section-header {
	font-size: 1.2em;

	.number {
		color: $bg-gray;
	}

	.title {
		text-transform: lowercase;
	}
}