@import "../../scss/_vars.scss";

.about-me {
	display: flex;
	flex-direction: column;
	align-items: center;
	a {
		color: $font-color-light
	}

	.content {
		display: flex;
		flex-direction: column;
		width: 65vw;
	}

	.bubble-row {
		margin-top: 3em;
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		.bubble {
			img{
				width: 18em;
				height: 18em;
			}
		}

		.timeline-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-left: 2em;
		}
	}

	.blurb {
		margin-top: 1.5em;
	}
}